import { ReactPlugin as ApplicationInsightsReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web'
import { createBrowserHistory } from 'history'
import { ErrorInfo, useEffect } from 'react'
import { User } from '../models/user'

export const appInsightsPlugin = new ApplicationInsightsReactPlugin()
export let appInsightsInstance: ApplicationInsights | undefined

const initializeAppInsights = () => {
  appInsightsInstance = new ApplicationInsights({
    config: {
      instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
      connectionString: process.env.REACT_APP_APPLICATION_INSIGHTS,
      extensions: [appInsightsPlugin],
      extensionConfig: {
        [appInsightsPlugin.identifier]: { history: createBrowserHistory() },
      },
      disableFetchTracking: false,
    },
  })
  appInsightsInstance.loadAppInsights()
}

if (!appInsightsInstance) {
  initializeAppInsights()
}

export const trackException = (
  level: SeverityLevel,
  args: {
    error: Error
    info: ErrorInfo
    componentName?: string
    message?: string
  },
) => {
  appInsightsInstance?.trackException({
    exception: args.error,
    severityLevel: level,
    properties: {
      info: args.info,
      componentName: args.componentName,
      message: args.message,
    },
  })
}

export const useAppInsightsUserContext = (user: User | null) => {
  useEffect(() => {
    if (user) {
      appInsightsInstance?.setAuthenticatedUserContext(user.email)
    } else {
      appInsightsInstance?.clearAuthenticatedUserContext()
    }

    if (appInsightsInstance) {
      const addTelemetryInitializer = () => {
        appInsightsInstance?.addTelemetryInitializer((envelope) => {
          if (user?.email) {
            envelope.data = envelope.data || {}
            envelope.data.baseData = envelope.data.baseData || {}
            envelope.data.baseData.properties = envelope.data.baseData.properties || {}
            envelope.data.baseData.properties['user_email'] = user.email
          }
        })
      }
      addTelemetryInitializer()
    }
  }, [user])
}
