import { LogLevel, PublicClientApplication } from '@azure/msal-browser'

const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI ?? `${window.location.origin}/auth/callback`

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AUTH_CLIENTID || '',
    authority: process.env.REACT_APP_AUTH_AUTHORITY,
    redirectUri: REDIRECT_URI,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            break
          case LogLevel.Info:
            console.info(message)
            break
          case LogLevel.Verbose:
            console.debug(message)
            break
          case LogLevel.Warning:
            console.warn(message)
            break
        }
      },
      logLevel: LogLevel.Error,
    },
  },
}

export const msalInstance = new PublicClientApplication(msalConfig)
