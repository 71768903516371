export const PATH = {
  HOME: "/",
  FORBIDDEN: "/forbidden",
  REQUESTACCESS: "https://forms.office.com/r/bAYURbrmrE",
  DEWATERING: "/dewatering",
  ABOUT: "/about",
  SITE_OVERVIEW: "/sites",
  FEEDBACK: "https://forms.office.com/pages/responsepage.aspx?id=gN9BQ-b7v0GJsObiN5ycI0z2KwZw5t5Or5NwiQnT2nVUQ0s3RjRES1NZWDY4SkNKSFhMMVk0VkZSQy4u",
  LOGOUT: "/.auth/logout",
  FEEDBACK_DEWATERING: "https://forms.office.com/r/ceNuQxbcGc",
};