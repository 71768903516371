export const pageTitle = {
  dewateringDefault: 'Mine Water Management',
  home: 'Home',
  feedback: 'Feedback',
  forbidden: 'Forebidden',
  logout: 'Logout',
  help: 'Help',
  about: 'About',
  siteOverview: 'Site Overview',
  faultType: 'Fault Type',
}
