import { navigationDrawerAtom } from '../../../store/atoms/navigation-drawer'
import { useAtom } from 'jotai'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

export const navDrawerTestId = 'navigation-drawer-control'
export const closeIconTestId = 'close-cross-icon'
export const menuIconTestId = 'menu-burger-icon'

export const NavigationDrawerControl = () => {
  const [navState, setOpen] = useAtom(navigationDrawerAtom)

  return (
    <IconButton
      aria-label="Toggle Navigation Menu"
      color="primary"
      data-testid={navDrawerTestId}
      onClick={() => {
        setOpen((state) => !state)
      }}
    >
      {navState ? <CloseIcon data-testid={closeIconTestId} /> : <MenuIcon data-testid={menuIconTestId} />}
    </IconButton>
  )
}
