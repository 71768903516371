import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { Meta } from '../components/meta'
import { pageTitle } from '../data/constants/page-titles'

export const Core = () => {
  return (
    <ErrorBoundary fallback={<></>}>
      <Suspense fallback={<></>}>
        <Meta titleTemplate={`%s | ${pageTitle.dewateringDefault}`} />
        <Outlet />
      </Suspense>
    </ErrorBoundary>
  )
}
